import React from 'react';
import styles from '../styles/Header.module.scss'
import { CgFilters } from 'react-icons/cg';
import { GoTag } from 'react-icons/go';
import { VscFeedback } from 'react-icons/vsc';
import { HiMenuAlt2 } from 'react-icons/hi';
import { useHistory } from 'react-router-dom';
import { toggleDocListLink, toggleTagListLink, specialPaneTypes, documentListKey, tagListKey } from '../util/links';
import { Filter } from './Filter';

export interface HeaderProps {
}

const iconSize = "2em"

export function Header(props: HeaderProps) {
    const history = useHistory();

    const toggleDocumentList = () => {
      history.push(toggleDocListLink(history.location))
    }
    
    const toggleTagList = () => {
      history.push(toggleTagListLink(history.location))
    }

    
    // const createNew = () => {
    //   const document = docStore.update(makeDocument())
    //   history.push(newDocLink(document._id))
    // }


    const feedback = () => {
      history.push('/feedback')
    }



    return (
      <div className={styles.header}>
        <button 
          className={styles.headerButton}
          onClick={toggleDocumentList}>
            <HiMenuAlt2 size={iconSize}/>
            <span>{specialPaneTypes[documentListKey]['title']}</span>
        </button>
        
        {/* <button 
          onClick={createNew}>
            <BsPlusSquare className={styles.addDocIcon} size = {iconSize}/>
            <span>New Document</span>
        </button> */}

        <button
          className={styles.headerButton}
          onClick={toggleTagList}>
          <GoTag size={iconSize} />
          <span>{specialPaneTypes[tagListKey]['title']}</span>
        </button>

          <button
            className={styles.headerButton}>
            <CgFilters size={iconSize}/>
            <span>Filter</span>
            <Filter />
          </button>


        <button 
          className={styles.headerButton}
          style={{marginLeft: 'auto'}}
          onClick={feedback}>
            <VscFeedback size = {iconSize} />
            Feedback
        </button>

        {/* 
        <span>
          Filter
        </span>

        
        
        <span>
          Timelines
        </span>

        <span>
          Map
        </span> */}
      </div>
    )
}

