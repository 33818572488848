import { useEffect, useState } from "react"
import { docStore } from "../store"
import { Subject } from "rxjs";
import { collectionTypes } from '../util/links';
import { useThing } from "./useThing";
import { FilterState } from "../models/State";
import _ from "lodash";
import { NoteThing } from "../models/NoteThing";

// the point of all of this is so we just keep a running table of the different
// collection types and we can do O(1) updates on it and we don't have to do
// O(n) getAll calls every time one thing changes.

const updates = new Subject<string>();
const thingsRecord: Record<string, Record<string, NoteThing>> = {}

docStore.allUpdates.pipe(
  ).subscribe((thingId) => {
    if (thingId === 'loaded') load();
    else {
      let thing = docStore.get(thingId);
      if (thing) {
        thingsRecord[thing.collectionType][thing._id] = thing;
      } else {
        thing = docStore.getDeleted(thingId);
        delete thingsRecord[thing.collectionType][thingId];
      }
      updates.next(thing.collectionType);
    }
  })
  
  function load() {
    for (let collectionType in collectionTypes) {
      thingsRecord[collectionType] = {};
      docStore.getAll(collectionType).forEach((thing) => {
        thingsRecord[collectionType][thing._id] = thing;
      })
    }
  }

export function useCollection<T>(collectionType: string, shouldFilter = true): T[] {
  const tagFilter: FilterState = useThing('tagFilter');
  // if (collectionType === 'tag') shouldFilter = false;
  const [things, setThings] = useState<any[]>(filter<T>(collectionType, shouldFilter, tagFilter))


  useEffect(() => {
    setThings(filter<T>(collectionType, shouldFilter, tagFilter))

    const sub = updates.subscribe((c) => {
      if (c === collectionType) {
        setThings(filter<T>(collectionType, shouldFilter, tagFilter))
      }
    });

    return () => sub.unsubscribe()
  }, [collectionType, tagFilter, shouldFilter]);


  return things;
}

function filter<NoteThing>(collectionType: string, shouldFilter: boolean, tagFilter: FilterState): NoteThing[] {
  let things: any[] = Object.values(thingsRecord[collectionType]);

  if (shouldFilter) {
    // for each thing, check all of its tags
    // if the tags intersect with the tag filter, keep it, otherwise drop it
    if (tagFilter.state.filterTags.length) {
      things = things.filter((thing) => {
        if (thing.collectionType === 'doc') return _.intersection(thing.tags, tagFilter.state.filterTags).length;
        else if (thing.collectionType === 'tag') return tagFilter.state.filterTags.includes(thing._id)
        else return false;
      });
    }
  }

  //@ts-ignore
  return things;
}

