import React from 'react';
import styles from '../styles/Filter.module.scss'
import { useThing } from '../hooks/useThing';
import { FilterState, updateState } from '../models/State';
import { Tags } from './Tags';


export interface FilterProps {

}

export function Filter(props: FilterProps) {

  const filterState = useThing<FilterState>('tagFilter');

  const addTagToFilter = (tagId: string) => {
    filterState.state.filterTags.push(tagId);

    updateState('tagFilter', filterState.state);
    
  }  

  const removeTagFromFilter = (tagId: string) => {
    const tagIndex = filterState.state.filterTags.findIndex((tag) => tag === tagId);

    if (tagIndex !== -1) {
      filterState.state.filterTags.splice(tagIndex, 1);
    }

    updateState('tagFilter', filterState.state);
  }

  const clearFilter = () => {
    updateState('tagFilter', {filterTags: []});
  }

  return (
    <div className={styles.filter}>
      <Tags
        addTag={addTagToFilter}
        removeTag={removeTagFromFilter}
        hasTagIds={filterState.state.filterTags} />
      {filterState.state.filterTags.length > 0 && 
        <button onClick={clearFilter} className={styles.clearFilterButton}>
          Clear
        </button>}
    </div>
  )
}

