import React, { useState } from 'react';
import styles from '../styles/NoteLine.module.scss'
import { FaTrash } from 'react-icons/fa';
import { Link, useHistory } from 'react-router-dom';
import { encodeLink, removeDocFromLink, insertDocAtEnd, insertDocAtBeg } from '../util/links';
import { ColorPicker } from './ColorPicker';
import { docStore } from '../store';
import { NoteThing } from '../models/NoteThing';
import { removeNote } from '../models/Doc';
import { getTagColorStyles } from '../models/NoteTag';
import { BsBoxArrowInDownRight, BsBoxArrowInDownLeft } from 'react-icons/bs';


export interface NoteLineProps {
  note: NoteThing
}

export function NoteLine(props: NoteLineProps) {
  const { note } = props;

  const linkJSON: string[][] = [[note._id]]
  const encodedLink = encodeLink(linkJSON)

  const [hover, setHover] = useState(false)
  const history = useHistory();

  const deleteNote = () => {
    history.replace(removeDocFromLink(note._id, history.location));
    removeNote(note._id);
  }

  const colorChange = (color: string) => {
    note.color = color;
    docStore.update(note);
  }

  const addToEnd = () => {
    const newUrl = insertDocAtEnd(note._id, history.location)
    if (newUrl) {
      history.push(newUrl);
    }
  }

  const addToBeg = () => {
    const newUrl = insertDocAtBeg(note._id, history.location)
    if (newUrl) {
      history.push(newUrl);
    }
  }

  const hoverStyles = getTagColorStyles(note);

  return (
    <div 
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      className={styles.noteLine}
      style={hover ? hoverStyles : {}}>
      {note.color && 
        <ColorPicker updateColor={colorChange} color={note.color} />}
        <Link to={encodedLink} 
        style={(hover && hoverStyles) ? {color: hoverStyles?.color} : {}}
      >
        {note.title}
      </Link>
      <div  
        style={{visibility: hover ? 'visible' : 'hidden'}} 
        className={styles.noteButtons}>
          <BsBoxArrowInDownLeft onClick={addToBeg} />
          <BsBoxArrowInDownRight onClick={addToEnd} />
          <FaTrash onClick={deleteNote} />
      </div>
    </div>
  )
}

