import PouchDB from 'pouchdb';
import { appName } from './project';
import { Subject } from 'rxjs';
import _ from 'lodash';
import { filter } from 'rxjs/operators';
import { defaultStates, State } from './models/State';

/* eslint @typescript-eslint/consistent-type-assertions: "off" */

const databaseName = appName

export class Store {
  private pool: Record<string, any> = {}
  public allUpdates: Subject<string> = new Subject<string>()
  public db: PouchDB.Database
  private defaultStates: State[]
  private loaded: boolean = false

  constructor(
    defaultStates: State[] = [],
    ) {
    this.db = new PouchDB(databaseName);
    this.defaultStates = defaultStates;
  }

  private initializeDefaultState(states: State[]) {
    for (let state of states) {
      if (!this.pool[state._id]) {
        this.update(state);
      }
    }
  }

  public update(thing: any) {
    if (!thing) return;
    return this._update(thing)
  }
  
  private _update(thing: any) {
    if (!thing) return;
    this.pool[thing._id] = thing;
  
    if ((thing.collectionType === 'state' && thing.persist === true) || thing.collectionType !== 'state') {
      this._put(thing)
    }
  
    this._notify(thing);
  
    return thing;
  }

  public remove(thingId: any) {
    this.update({
      ...this.get(thingId),
      _deleted: true
    })
    
  }

  private _notify(thing: any) {
    if (this.loaded) {
      this.allUpdates.next(thing._id);
    }
  }

  public async _put(thing: any) {
    try {
      var oldThing = await this.db.get(thing._id);
      const newThing = {
        ...thing,
        _rev: oldThing._rev,
      }
      await this.db.put(newThing);
    } catch(error) {
      if (error.name === 'not_found' && error.reason === 'deleted') {
          const deletedThing = await this.db.get(thing._id, {open_revs:"all"})
          oldThing = deletedThing[0].ok
          const newThing = {
            ...thing,
            _rev: oldThing._rev
          }
          await this.db.put(newThing);
        } else if (error.name === 'not_found' && error.reason === 'missing') {
          delete thing._rev;
          await this.db.put(thing);
        } else {
          await this.db.put(thing);
        }
    }
    return thing;
  }

  public async loadMetadata() {
    const metadataId = '_local/metadata'

    let isNew = false;

    await this.db.get(metadataId).then((metadata) => metadata).catch((error) => {
      isNew = true;
    })

    if (isNew) {
      await this._put({
        _id: metadataId
      })
    }

    return isNew;
  }

  public async loadAll() {
    (await this.db.allDocs({include_docs: true})).rows.forEach(row => {
      // @ts-ignore
      if (row.doc) {
        this.pool[row.doc._id] = row.doc
      }
    })

    this.initializeDefaultState(this.defaultStates);
    this.convertOldData();

    this.loaded = true;
    this._notify({_id: 'loaded'})
  }

  private convertOldData(): any[] {
    const convertedThings: any[] = [];
    for (let thing of Object.values(this.pool)) {
      if (thing.collectionType === 'doc' && !thing.tags) {
        // @ts-ignore
        thing.tags = [];
        convertedThings.push(thing)
      }
    }
    return convertedThings;
  }

  public get(thingId: string | undefined): any {
    if (thingId === undefined) return undefined;
    const thing = this.pool[thingId]
    if (!thing) return undefined;
    if (thing._deleted) return undefined;
    return _.cloneDeep(thing);
  }

  public getDeleted(thingId: string | undefined): any {
    if (thingId === undefined) return undefined;
    const thing = this.pool[thingId];
    if (!thing) return undefined;
    return _.cloneDeep(thing);
  }

  public getAll(collectionTypeFilter?: string): any[] {
     let ret = Object.values(this.pool).filter(thing => {
       if (thing._deleted) return false;
       if (typeof collectionTypeFilter === 'string') {
         if (thing['collectionType'] === collectionTypeFilter) return true;
         else return false;
       }
      //  if (collectionType && thing[collectionType] === collectionType) return false;
      return true;
    })

    return ret;
  }

  public exportJSON() {
    return JSON.stringify(this.pool);
  }

  public importJSON(json: any) {
    for (let note of json) {
      delete note._rev;
      docStore.update(note);
    }
    // Object.entries(json).forEach(async ([_id, doc]) => {
    //   //@ts-ignore
    //   delete doc._rev;
    //   docStore.update(doc);
    // });
  }
  
  public thingSub(thingId: string) {
    return this.allUpdates.pipe(
      filter(id => id === thingId)
    )
  }
}

const initialStates = Object.values(defaultStates);

export const docStore = new Store(initialStates);





// function pouchDatabaseExists(dbName: string) {
//   return new Promise((resolve, reject) => {
//     const req = indexedDB.open(`_pouch_${dbName}`);
//     req.onsuccess = () => resolve(true)
//     req.onupgradeneeded = () => resolve(false)
//   })
// }

// async function storeStart() {
//   let dbExists = await pouchDatabaseExists(databaseName);

//   Store.db = new PouchDB(appName)
//   console.log(dbExists);
// }

// storeStart();



