import React from 'react';
import styles from '../styles/ReaderPane.module.scss'
import { useThing } from '../hooks/useThing';
import { Backlinks } from './Backlinks';
import { Editor } from './Editor';
import { Doc } from '../models/Doc';
import { DocTags } from './DocTags';


export interface ReaderPaneProps {
  documentId: string
  horizontalPaneIndex: number
  verticalPaneIndex: number
  // paneIndex: number
  // allDocs: Doc[]
  // focus: (inst: any, e: any) => void
}

export function ReaderPane(props: ReaderPaneProps) {
  // const { focus, allDocs, documentId, paneIndex } = props;
  const { documentId, horizontalPaneIndex, verticalPaneIndex } = props;
  const doc = useThing<Doc>(documentId);

  return (
    <div className={styles.readerPane}>
      {doc.collectionType === 'doc' && <DocTags
        doc={doc} />}
      <Editor 
        verticalPaneIndex={verticalPaneIndex}
        horizontalPaneIndex={horizontalPaneIndex}
        doc={doc}
      />
      <Backlinks  
        horizontalPaneIndex={horizontalPaneIndex}
        backlinks={doc.backlinks}
      />
    </div>
  )
}

