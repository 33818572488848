import React from 'react';
import styles from '../styles/LinkModal.module.scss'
import Popover from 'react-tiny-popover';
import { NoteThing } from '../models/NoteThing';


export interface LinkModalProps {
  selIndex: number
  showModal: boolean
  filteredDocs: NoteThing[]
}


export const LinkModal = React.forwardRef<HTMLDivElement, LinkModalProps>((props, ref) => {
  const { filteredDocs, showModal, selIndex } = props;

  return (
    <div ref={ref}>
      <Popover
        containerClassName={styles.modalContainer}
        position='bottom'
        transitionDuration={0}
        align='start'
        isOpen={showModal}
        content={
          <div className={styles.modal}>
            {!filteredDocs.length && 
            <div>
              <code>+ Enter to create new...</code>
            </div>}
            <div className={styles.docList}>
              {filteredDocs.map((doc, i) => {
                return (
                  <code 
                    className={[styles.link, i === selIndex ? styles.selectedLink : ""].join(" ")}
                    key ={doc._id}>
                    {doc.title}
                  </code>)})}
            </div>
          </div>
        }>
          <span className={styles.modalHandle}></span>
      </Popover>
    </div>
  )
});


