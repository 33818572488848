import React from 'react';
import styles from '../styles/NoteList.module.scss'
import { useCollection } from '../hooks/useCollection';
import { NoteLine } from './NoteLine';
import { NewNote } from './NewNote';
import { NoteThing } from '../models/NoteThing';


export interface NoteListProps {
  collectionType: string
}

export function NoteList(props: NoteListProps) {
  const { collectionType } = props;
  const notes = useCollection<NoteThing>(collectionType, false)

  return (
    <div className={styles.noteList}>
      {<NewNote collectionType={collectionType}/>}
      {notes.map((note) => <NoteLine key={note._id} note={note} />)}
    </div>
  )
}

