import React from 'react';
import styles from '../styles/Backlinks.module.scss'
import { docStore } from '../store';
import { useHistory } from 'react-router';
import { generateNextPaneLink } from '../util/links';
import { defaultState } from '../models/State';
import { Backlink } from './Backlink';


export interface BacklinksProps {
  backlinks: string[],
  horizontalPaneIndex: number
}

export function Backlinks(props: BacklinksProps) {
  const { backlinks, horizontalPaneIndex } = props;

  const history = useHistory()


  const navToBacklink = (e: any, backlink: string) => {
    history.push(generateNextPaneLink(backlink, history.location, horizontalPaneIndex))

    docStore.update({
      ...defaultState('focusState'),
      state: { focus: backlink }
    })
  }

  return (
    <div className={styles.backlinkContainer}>
      {backlinks.length > 0 && <h4 className={styles.backlinksContainerHeader}>Links to this note</h4>}
      {backlinks.map((backlink) => 
        <Backlink key={backlink} backlink={backlink} onClick={navToBacklink} /> 
      )}
    </div>
  )
}

