import React, {useState, ChangeEvent } from 'react';
import styles from '../../styles/Input.module.scss';


export interface InputProps {
  onSubmit: (value: string) => void
  onChange?: (value: string) => void
  transform?: (value: string) => string
  onFocus?: () => void
  onBlur?: () => void
  validation?: (input: string) => boolean
  preventBlurSubmit?: boolean
  className?: string
  placeholder?: string
  initialValue: string

}

export const Input = React.forwardRef<HTMLInputElement, InputProps>((props, ref) => {
  const { validation, onBlur: onBlurProp, preventBlurSubmit, onFocus, 
    transform, onChange, placeholder, onSubmit, initialValue, className } = props;
  const [value, setValue] = useState(initialValue);
  
  const onSubmitForm = (e: any) => {
    submit(e);
  }
  
  const onBlur = (e: any) => {
    onBlurProp && onBlurProp();
    if (preventBlurSubmit) return;
    submit(e);
  }
  
  const submit = (e: any) => {
    e.preventDefault();
    if (!valid) return;
    onSubmit(value);
    setValue(initialValue);
    if (preventBlurSubmit) {
      //@ts-ignore
      document.activeElement.blur();
    }
  }
  

  
  const inputChange = (e: ChangeEvent<HTMLInputElement>) => {
    let newVal = e.target.value;
    newVal = transform ? transform(newVal) : newVal
    setValue(newVal)
    onChange && onChange(newVal);
    validation && console.log(validation(newVal));
    validation && setValid(validation(newVal));
  }
  
  const [valid, setValid] = useState(true);
  
  return (
    <div>
      <form
        onSubmit={onSubmitForm}>
        <input 
          ref={ref}
          className={[valid ? '' : styles.invalid, styles.input, className ? className : ''].join(' ')}
          value = {value}
          placeholder={placeholder}
          onChange={inputChange}
          onBlur={onBlur}
          onFocus={onFocus ? onFocus : undefined}
          >
        </input>
      </form>
    </div>
  )

});

export function Inputd(props: InputProps) {
}