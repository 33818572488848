import React, {  useEffect, useRef, useState } from 'react'
import EasyMDE from 'easymde';
import styles from '../styles/Editor.module.scss'
import { docStore } from '../store';
import { Doc, saveDocument, removeNote, makeNewDoc } from '../models/Doc';
import { useHistory } from 'react-router';
import { LinkModal } from './LinkModal';
import { generateNextPaneLink, removeDocFromLink, moveFocusLeft, moveFocusRight, moveFocusUp, moveFocusDown, openNetworkUrl } from '../util/links';
import { drawLink, toggleBold, toggleItalic, toggleStrikethrough, togglePreview } from 'easymde';
import { useThing } from '../hooks/useThing';
import { FocusState, updateState } from '../models/State';
import { filter } from 'rxjs/operators';
import { PopoverWrapper } from './PopoverWrapper';
import CodeMirror from 'codemirror';
import { useCollection } from '../hooks/useCollection';
import { StoredLink, NoteThing } from '../models/NoteThing';
import { NoteTag, getTagColorStyles } from '../models/NoteTag';


export interface EditorProps {
  doc: Doc
  horizontalPaneIndex: number
  verticalPaneIndex: number
}


/* eslint react-hooks/exhaustive-deps: "off" */

const evergreenOverlay = {
  startState: function() { return {start: 0, linkText: ''};},
  token: function(stream: any, state: any) {
    //@ts-ignore
    var ch;
    if (stream.match('[[')) {
      while ((ch = stream.next()) != null) 
        if (ch === ']' && stream.next() === ']') {
            stream.eat(']');
            state.linkText = stream.current();
            state.start = stream.column();
            return 'doclink';
          }
      }
      while (stream.next() != null && !stream.match('[[', false))
      state.linkText = '';
      return null;
    },
  }

interface PopoverProps {
  docId: string
  coords: {top: number, left: number}
}

export function Editor(props: EditorProps) {
  const { doc, horizontalPaneIndex, verticalPaneIndex } = props;

  const [showNewLinkModal, setShowNewLinkModal] = useState(false);
  const [activeLink, setActiveLink] = useState<CodeMirror.TextMarker | null>(null);
  const [filterText, setFilterText] = useState('');
  const [selIndex, setSelIndex] = useState(0);
  const [popover, setPopover] = useState<PopoverProps | null>(null);
  const [clearFilter, setClearFilter] = useState<boolean>(false);
  const [enableFilter, setEnableFilter] = useState(true);
  const docs = useCollection<Doc>('doc', enableFilter);
  const tags = useCollection<NoteTag>('tag', enableFilter);
  
  const easyMDERef = useRef<EasyMDE>()
  const history =  useHistory()

  const filterDocs = clearFilter ? [] : 
    (docs as NoteThing[])
    .filter(otherDoc => otherDoc._id !== doc._id)
    .concat(tags)
    .filter((otherDoc) => otherDoc.title && otherDoc.title.toLowerCase().includes(filterText.toLowerCase()))

  const updateEnableFilter = (newEnableFilter: boolean) => {
    if (enableFilter !== newEnableFilter) {
      setSelIndex(0);
    }

    setEnableFilter(newEnableFilter);
  }

  const updateActiveLink = (newActiveLink: CodeMirror.TextMarker | null) => {
    if (activeLink) {
      activeLink?.replacedWith?.classList.remove('cm-highlighted-doclink')
    }

    if (newActiveLink) {
      newActiveLink.replacedWith?.classList.add('cm-highlighted-doclink')
    }

    setActiveLink(newActiveLink);
  }

  const updateNewLinkModal = (show: boolean) => {
    if (!show) {
      setSelIndex(0);
      setFilterText('');
      setClearFilter(false)
    }
    setShowNewLinkModal(show)
  }

  const submit = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    //@ts-ignore
    document.activeElement?.blur(); 
  }

  const save = () => {
    updateNewLinkModal(false);
    if (easyMDERef && easyMDERef.current) {
      saveDocument(doc._id, easyMDERef.current.codemirror);
    }
  }

  const cutDocument = () => {
    history.push(removeDocFromLink(doc._id, history.location, horizontalPaneIndex))
  }

  const deleteDocument = () => {
    easyMDERef.current?.clearAutosavedValue();
    history.replace(removeDocFromLink(doc._id, history.location, horizontalPaneIndex))

    removeNote(doc._id);
  }

  const openLinkNetwork = () => {
    history.push(openNetworkUrl(history.location, doc._id));
  }


  const clearTextMarker = (textMarker: CodeMirror.TextMarker) => {
    textMarker.clear();
    if (popover && popover.docId === getTextMarkerDocId(textMarker)) {
      setPopover(null);
    }
  }

  // const togglePublic = () => {
  //   Store.update({
  //     ...Store.get(doc._id),
  //     public: !doc.public
  //   })
  // }

  const openLink = (marker: CodeMirror.TextMarker | string) => {
    let documentId = typeof marker === 'string' ? marker : marker.replacedWith?.getAttribute('linkForId') || ''
    if (!documentId) return;
    history.push(generateNextPaneLink(documentId, history.location, horizontalPaneIndex))

    updateState('focusState', {focus: documentId})
    // Store.update({
    //   ...defaultState('focusState'),
    //   state: { focus: documentId }
    // })
  }

  // const showPopover = (props: PopoverProps | null) => {
  //   // let coords = elem.getBoundingClientRect();
    
  //   setPopover({doc: documentId, coords: {top: coords.top, left: coords.left + coords.width}});
  // }

  const insertLink = (inst: CodeMirror.Editor, doc: NoteThing) => {
    markLink(inst, doc, inst.getCursor())
  }

  const markInnerHTML = (doc: NoteThing) => {
    const leftBracketSpan = document.createElement('span');
    leftBracketSpan.classList.add('bracket-span');
    leftBracketSpan.innerHTML = '[[';

    const rightBracketSpan = document.createElement('span');
    rightBracketSpan.classList.add('bracket-span');
    rightBracketSpan.innerHTML = ']]';

    const innerSpan = document.createElement('span');
    innerSpan.classList.add('inner-span');
    innerSpan.innerHTML = doc.title;

    return `${leftBracketSpan.outerHTML}${innerSpan.outerHTML}${rightBracketSpan.outerHTML}`;
  }

  const markLink = (inst: CodeMirror.Editor, doc: NoteThing, position: CodeMirror.Position) => {
    const tokens = inst.getTokenAt(position);
    if (!tokens.type) return;

    const {linkText, start} = tokens.state.overlay;

    let startPos = {line: position.line, ch: start}
    let endPos = {line: position.line, ch: start + linkText.length}

    inst.getDoc().replaceRange(`[[${doc.title}]]`, 
    startPos, endPos, linkText)

    let replacedWith = document.createElement('span')

    tokens.type.split(' ')
      .filter(type => type !== 'doclink' && type !== 'spell-error')
      .concat(['active-doclink'])
      .forEach((cssClass) => {
        replacedWith.classList.add('cm-' + cssClass)
      })

    replacedWith.setAttribute('linkforid', doc._id);
    replacedWith.innerHTML = markInnerHTML(doc);

    if (doc.color) {
      const {backgroundColor} = getTagColorStyles(doc);
      replacedWith.style.backgroundColor = backgroundColor;
    }

    replacedWith.addEventListener('click', (e: MouseEvent) => {
      openLink(doc._id);
      setPopover(null);
    })

    replacedWith.addEventListener('mouseenter', (e: MouseEvent) => {
      const coords = replacedWith.getBoundingClientRect();
      setPopover({docId: doc._id, coords: {top: coords.top, left: coords.left + coords.width}})
    })

    replacedWith.addEventListener('mouseleave', (e: MouseEvent) => {
      setPopover(null)
    })

    endPos.ch = start + doc.title.length + 4

    return inst.getDoc().markText(startPos, endPos, {
      replacedWith,
    });

  }

  const modalRef = useRef(document.createElement('div'));

  const getLinkText = (inst: CodeMirror.Editor): string | false => {
    const cursor = inst.getCursor();

    // getting tokentypes is much cheaper than getting all the tokens so only
    // get them if we have to
    const tokenTypes = inst.getTokenTypeAt(cursor);

    if (tokenTypes && tokenTypes.includes('doclink')) {
      const tokens = inst.getTokenAt(cursor);
      if (!tokens.type) return false;
      return tokens.state.overlay.linkText;
    } else return false;
  }
  
  const cursorActivity = (inst: CodeMirror.Editor) => {
    let cursor = inst.getCursor()
    if (inst.hasFocus()) {
      const { bottom } = inst.cursorCoords(false, 'page');

      updateState('cursorPosition', { bottom, horizontalPaneIndex, verticalPaneIndex})
    }
    let marks = inst.findMarksAt(cursor)
    let activeLink: CodeMirror.TextMarker | null = null, showNewLinkModal;
    if (marks.length) {
      activeLink = marks[0];
    }

    if (activeLink) {
      showNewLinkModal = false;
    } else {
      let linkText = getLinkText(inst);
  
      if (linkText === false) {
        showNewLinkModal = false
      }
      else {
        showNewLinkModal = true
        if (linkText[2] === ':') {
          updateEnableFilter(false)
          linkText = linkText.slice(0, 2) + linkText.slice(3);
        } else {
          updateEnableFilter(true)
        }

        setFilterText(linkText.slice(2, linkText.length - 2));

      }
    }
    
    if (showNewLinkModal) {
      inst.addWidget(cursor, modalRef.current, true)
    } 

    updateActiveLink(activeLink)
    updateNewLinkModal(showNewLinkModal)
  }

  const keydown = (inst: CodeMirror.Editor, e: KeyboardEvent) => {
    let prevent = false;
    const cursor = inst.getCursor()

    if (e.altKey && !showNewLinkModal) {
      let refocused = false;
      if (e.key === 'ArrowLeft') {
        refocused = moveFocusLeft(history.location, horizontalPaneIndex);
      } else if (e.key === 'ArrowRight') {
        refocused = moveFocusRight(history.location, horizontalPaneIndex);
      } else if (e.key === 'ArrowUp') {
        refocused = moveFocusUp(history.location, horizontalPaneIndex, verticalPaneIndex);
      } else if (e.key === 'ArrowDown') {
        refocused = moveFocusDown(history.location, horizontalPaneIndex, verticalPaneIndex);
      }

      if (refocused) {
        prevent = true;
      }
    }

    // auto fill out [[]] when user types [[
    if (!activeLink && !showNewLinkModal) {
      if (e.key === '[') {
        if (inst.getRange({...cursor, ch: cursor.ch - 1}, cursor ) === '[') {
          prevent = true;
          inst.getDoc().replaceRange('[]]', cursor, cursor)
          inst.setCursor(cursor.line, cursor.ch + 1)
        }
      }
    }

    if (activeLink !== null) {
      if (e.key === 'Enter') {
        e.preventDefault();
        e.stopPropagation();
        openLink(activeLink);
      }
      else if (!inst.getDoc().somethingSelected()) {
        let markerLocation = activeLink.find()
        if (!markerLocation) return;
        let clear = false;
        if (e.key === 'Backspace' && cursor.ch >= markerLocation.to.ch) {
          clear = true;
        }
        else if (!e.shiftKey && e.key === 'Delete' && cursor.ch <= markerLocation.from.ch) {
          clear = true
        }
        if (clear) {
          prevent = true;
          clearTextMarker(activeLink)
          cursorActivity(inst);
        }
      }
    } else if (showNewLinkModal) {
      if (e.key === 'ArrowUp') {
        if (selIndex > 0) {
          prevent = true;
          setSelIndex(selIndex - 1)
        }
      } else if (e.key === 'ArrowDown') {
        if (selIndex < filterDocs.length - 1) {
          prevent = true;
          setSelIndex(selIndex + 1)
        }
      } else if (e.key === 'Escape') {
        if (!clearFilter) {
          setClearFilter(true);
        } else {
          updateNewLinkModal(false)
        }
      } else if (e.key === 'Enter') {
        prevent = true;
        if (filterDocs[selIndex]) {
          insertLink(inst, filterDocs[selIndex])
        } else {
          const newDoc = makeNewDoc({title: filterText});
          insertLink(inst, newDoc);
        }
        updateNewLinkModal(false);
      }
    }

    if (prevent) {
      e.stopPropagation();
      e.preventDefault();
    }
  }
  

  
  useEffect(() => {
    const textarea = document.getElementById(doc?._id)
    if (doc && textarea) {
      easyMDERef.current = new EasyMDE({
        overlayMode: {
          mode: evergreenOverlay,
          combine: true
        },
        initialValue: doc.content,
        spellChecker: false,
        element: textarea,
        toolbar: [
          {
            name: 'delete',
            action: deleteDocument,
            className: "fa fa-trash",
            title: 'Delete'
          },
          // {
          //   name: 'publish',
          //   action: togglePublic,
          //   className: 'fa fa-lock',
          //   title: 'Publish'
          // },
          {
            name: 'openAllLinks',
            action: openLinkNetwork,
            className: 'fa fa-arrows-alt',
            title: 'Open Network'
          },
          {
            name: 'cut',
            action: cutDocument,
            className: 'fa fa-times',
            title: 'Close Document'
          },
          '|',
          {
            name: 'link',
            action: drawLink,
            className: 'fa fa-link',
            title: 'Create Link'
          },
          {
            name: 'bold',
            action: toggleBold,
            className: 'fa fa-bold',
            title: 'Bold'
          },
          {
            name: 'italic',
            action: toggleItalic,
            className: 'fa fa-italic',
            title: 'Italic'
          },
          {
            name: 'strikethrough',
            action: toggleStrikethrough,
            className: 'fa fa-strikethrough',
            title: 'Strikethrough'
          },
          '|',
          {
            name: 'preview',
            action: togglePreview,
            className: 'fa fa-eye no-disable',
            title: 'Preview'
          },
          // {
          //   name: 'fullscreen',
          //   action: toggleFullScreen,
          //   className: 'fa fa-arrows-alt no-disable no-mobile',
          //   title: 'Full Screen'
          // },

        ],
        minHeight: '100%',
        autosave: {
          enabled: true,
          uniqueId: doc._id
          }
      })

      const cm = easyMDERef.current.codemirror

      cm.setOption('viewportMargin', Infinity)
      doc.links.forEach((link) => { 
        let targetDoc = docStore.get(link.targetId);
        if (targetDoc) {
          markLink(cm, targetDoc, link.to);
        }
      });

      updateNewLinkModal(false)
    }

    //@ts-ignore
    return () => easyMDERef?.current?.cleanup();
  }, [])

  useEffect(() => {
    let cm: CodeMirror.Editor;
    if (easyMDERef.current) {
      cm = easyMDERef.current.codemirror
    
      cm.on('cursorActivity', cursorActivity)
      cm.on('keydown', keydown)
      cm.on('blur', save)

    }
    return () => {
      if (cm) {
        cm.off('cursorActivity', cursorActivity);
        cm.off('keydown', keydown);
        cm.off('blur', save);
      }
    }
  })


  const focusState = useThing<FocusState>('focusState')

  useEffect(() => {
    if (focusState && easyMDERef.current) {
      if (focusState.state.focus === doc._id) {
        let inst = easyMDERef.current.codemirror
        inst.focus();
      }
    }
  }, [focusState])


  // update mark text when link name is changed from another place
  useEffect(() => {
    let storedLinkIds = getStoredLinkIds(doc.links);
    
    if (easyMDERef.current) {
      findObsoleteMarks(easyMDERef.current.codemirror, doc.links).forEach(clearTextMarker);
    }

    const sub = docStore.allUpdates.pipe(
      filter(id => storedLinkIds.includes(id))
    ).subscribe((changedLinkId) => {
      if (easyMDERef.current) {
        const inst = easyMDERef.current.codemirror 
        const targetDoc: Doc = docStore.get(changedLinkId);
        let marks = inst.getAllMarks().filter((mark) => mark.replacedWith?.getAttribute('linkforid') === changedLinkId)
        if (targetDoc) {
          // TODO - if we change the title and then delete the link without closing and reopening the document then we see the old title rather than the new one.

          marks.forEach((mark) => {

            
            mark.replacedWith!.innerHTML = markInnerHTML(targetDoc);
            mark.changed();
          })
        }
        else {
          console.log('we should never get here')
          marks.forEach((mark) => {
            mark.clear();
          })
        }
      }
    })

    return () => sub.unsubscribe();
  }, [doc.links])
  
  return (
    <>
    <LinkModal ref={modalRef} selIndex={selIndex} filteredDocs={filterDocs} showModal={showNewLinkModal} />
    {popover && 
      <PopoverWrapper
        isOpen={!!popover}
        documentId={popover.docId}
        contentLocation={popover.coords}>
        <div></div>
      </PopoverWrapper>}
    <div className={styles.editorContainer}>
      {doc ?
          <div className={styles.editor}>
          <form
            onSubmit={submit}>
              <textarea id={doc._id}></textarea>
          </form>
        </div>
        : <></>}
    </div>
    </>
  )
}


function findObsoleteMarks(inst: CodeMirror.Editor, links: StoredLink[]): CodeMirror.TextMarker[] {
  const obsoleteMarks: CodeMirror.TextMarker[] = [];
  const allMarks = inst.getAllMarks();

  const storedLinkIds = getStoredLinkIds(links)

  for (let mark of allMarks) {
    const markId = getTextMarkerDocId(mark);
    if (!markId) continue;
    if (!storedLinkIds.includes(markId)) {
      obsoleteMarks.push(mark);
    }
  }

  return obsoleteMarks;
}

function getTextMarkerDocId(textMarker: CodeMirror.TextMarker): string | undefined | null {
  return textMarker.replacedWith?.getAttribute('linkforid');
}

function getStoredLinkIds(storedLinks: StoredLink[]): string[] {
  return storedLinks.map(storedLink => storedLink.targetId);
}