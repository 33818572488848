import React from 'react';
import styles from '../styles/Select.module.scss'


export interface SelectProps {
  options: {value: string, display: string}[]
  onSubmit: (value: string) => void
}

export function Select(props: SelectProps) {
  const { options, onSubmit } = props;

  const onSubmitForm = (e: any) => {
    submit(e);
  }

  const submit = (e: any) => {
    e.preventDefault();
    onSubmit(e.target.value);
  }

  return (
    <div>
      <form className={styles.form} 
        onSubmit={onSubmitForm}>
        <select
          className={styles.select}
          value={""}
          onChange={submit}>
          <option className={styles.tagDoc} value="">Add Tag</option>
          {options.map((option) => 
            <option key={option.value} value={option.value}>{option.display}</option>
          )}
        </select>
      </form>
    </div>
  )
}

