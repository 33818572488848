import React from 'react';
import { Doc, addTagToDoc, removeTagFromDoc } from '../models/Doc';
import { Tags } from './Tags';


export interface DocTagsProps {
  doc: Doc
} 

export function DocTags(props: DocTagsProps) {
  const { doc } = props;

  const saveNewTag = (newTagId: string) => {
    if (!newTagId) return;
    addTagToDoc(newTagId, doc);
  }

  const deleteTag = (tagId: string) => {
    removeTagFromDoc(tagId, doc);
  }

  return (
    <Tags
      addTag={saveNewTag}
      removeTag={deleteTag}
      hasTagIds={doc.tags}/>
  )
}

