import React, { useState, useEffect, useRef } from 'react';
import styles from '../styles/EditorHeader.module.scss'
import { useThing } from '../hooks/useThing';
import { Input } from './widgets/Input';
import { docStore } from '../store';
import { Doc } from '../models/Doc';
import { updateState } from '../models/State';

export interface EditorHeaderProps {
  documentId: string
  title?: string
  collapsed: boolean
}

export function EditorHeader(props: EditorHeaderProps) {
  const { documentId, title, collapsed } = props;
  const doc = useThing<Doc>(documentId)

  const saveTitle = (newTitle: string) => {
    if (newTitle) {
      docStore.update({
        ...doc,
        title: newTitle
      })
  
    }
    setEditing(false)

  }

  const onClick = collapsed ? () => {
    updateState('focusState', {focus: documentId})
  } : () => setEditing(true)

  const [editing, setEditing] = useState(false)

  const ref = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (ref.current) {
      ref.current.focus();
      ref.current.select();
    }
  }, [editing])

  return (
    <div style={{cursor: title && !collapsed ? 'default' : 'pointer'}}className={styles.editorHeader}>
      {title ? <h3 onClick={collapsed ? onClick : undefined}>{title}</h3> :
        doc && 
        (editing ? 
        <Input ref={ref} className={styles.inputClass} onSubmit={(val) => saveTitle(val)} initialValue={doc.title}/> : 
        <h3 onClick={onClick}>{doc.title}</h3> )
      }
     
    </div>
  )
}

