import React from 'react';
import styles from '../styles/PopoverContent.module.scss'
import { docStore } from '../store';
import ReactMarkdown from 'react-markdown';
import { Doc } from '../models/Doc';


export interface PopoverContentProps {
  documentId: string
  style?: React.CSSProperties
}

export function PopoverContent(props: PopoverContentProps) {
  const { style, documentId } = props;

  const doc: Doc = docStore.get(documentId)

  return (
    doc ? <div 
    style={style}
    className={styles.popover}>
      <h3>
        {doc.title}
      </h3>
        <ReactMarkdown 
          source={doc.content.trim()}/>
    </div> : <div></div>
  )
}

