import { NoteThing } from "./NoteThing";
import tinyColor from 'tinycolor2';
import { v4 as uuid } from 'uuid';

export interface NoteTag extends NoteThing {
  collectionType: 'tag'
  taggedDocuments: string[]
}

export function makeTag(makeTag?: Partial<NoteThing>): NoteTag {
  let title = makeTag?.title || '#tag'
  if (title[0] !== '#') title = '#' + title;
    return {
    _id: uuid(),
    _deleted: false,
    links: [],
    backlinks: [],
    taggedDocuments: [],
    tags: [],
    content: "",
    public: false,
    ...makeTag,
    title,
    collectionType: 'tag',
  }
}

export function noteToOption(note: NoteThing): {value: string, display: string} {
  return {value: note._id, display: note.title};
}

export function filterDocTags(tags: NoteTag[], tagIds: string[]): NoteTag[] {
  return tags.filter((tag) => !tagIds.includes(tag._id))
}


export function getTagColorStyles(note: NoteThing): any {
  let backgroundColor = note.color || '#ddd';

  let colorInfo = tinyColor(backgroundColor);
  backgroundColor = colorInfo.setAlpha(0.5).toPercentageRgbString();
  let color = colorInfo.getBrightness()/255 > 0.5 ? 'black' : 'white'

  return {backgroundColor, color}
}