import React from 'react';
import styles from '../styles/PopoverWrapper.module.scss'
import Popover from 'react-tiny-popover';
import { PopoverContent } from './PopoverContent';


export interface PopoverWrapperProps {
  isOpen: boolean
  documentId: string
  contentLocation?: {top: number, left: number}
  children: JSX.Element | ((ref: React.Ref<any>) => JSX.Element);
}

export function PopoverWrapper(props: PopoverWrapperProps) {
  const { children, isOpen, documentId, contentLocation } = props;
  
  return (
    <Popover
      containerClassName={styles.popoverContainer}
      contentLocation={contentLocation}
      position={['right', 'left']}
      content={({position, targetRect, popoverRect}) => {
        return <PopoverContent 
                documentId={documentId} />
      }}
      // content={<PopoverContent documentId={documentId}/>}
      // content={({position, targetRect, popoverRect}) => (
      //   <ArrowContainer
      //     position={position}
      //     targetRect={targetRect}
      //     popoverRect={popoverRect}
      //     arrowSize={28}
      //     arrowColor={'rgb(225, 225, 225)'}
      //     arrowStyle={{
      //       // left: 15
      //     }}
      //     >
      //       <PopoverContent documentId={documentId} />
      //   </ArrowContainer>
      // )}
      isOpen={isOpen}>
      {children}
    </Popover>

  )
}

