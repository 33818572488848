import React, { useEffect, useState } from 'react';
import './App.css';
import { Switch, Route, BrowserRouter, Redirect } from 'react-router-dom';
import { docStore } from './store';
import { ReaderView } from './components/ReaderView';
import { decodeLink, fixBrokenLink, openNetworkUrl } from './util/links';
import { Header } from './components/Header';
import { Feedback } from './components/Feedback';
import dbSeed from './db_seed.json';

const AboutThisWebsiteId = "e9d03507-1828-4c3a-a122-cdbd4dd6359f";

function loadInitialData() {
  docStore.importJSON(dbSeed);
}

function App() {
  const [render, setRender] = useState(false);
  const [isNew, setIsNew] = useState(false);

  useEffect( () => {
    docStore.loadMetadata().then((isNew) => {
      if (isNew) {
        loadInitialData();
        setIsNew(true);
      }
      docStore.loadAll().then(() => {
        setRender(true)
      })
    }
    ).catch(error => console.log(error))
  }, [])

  useEffect(() => {
    if (isNew && render) {
      setIsNew(false);
    }
  }, [isNew, render]);

  return (
    <BrowserRouter>
    {render && <> 
      <Switch>
      <Route exact path="/feedback" render={() => <Feedback />} />
      <Route exact path="/:panes*" render = {(routeProps) => {
        const panes = decodeLink(routeProps.match.params.panes)
        let newRoute = fixBrokenLink(panes);
        if (isNew) {
          newRoute = openNetworkUrl(routeProps.history.location, AboutThisWebsiteId, true)
        }

        return (
        newRoute ? <Redirect to={newRoute}/> : 
        <div style={{height: '100vh'}}>
          <Header/>
          <ReaderView
            horizontalPanes={panes}/>
        </div>
        )
      }} />
      </Switch>
      </>}
    </BrowserRouter>
  );
}

export default App;
