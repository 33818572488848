import React, { useRef, useEffect } from 'react';
import styles from '../styles/HorizontalPane.module.scss'
import { EditorHeader } from './EditorHeader';
import { ReaderPane } from './ReaderPane';
import { useThing } from '../hooks/useThing';
import { FocusState, getState } from '../models/State';
import { Subscription } from 'rxjs';
import { docStore } from '../store';
import { specialPaneTypes } from '../util/links';
import { NoteList } from './NoteList';

/* eslint react-hooks/exhaustive-deps: "off" */

export interface HorizontalPaneProps {
  verticalPaneIds: string[]
  collapseClass: string
  paneStyles: any,
  horizontalPaneIndex: number
}

const verticalOffset = 60;

export function HorizontalPane(props: HorizontalPaneProps) { //= React.forwardRef<HTMLDivElement, HorizontalPaneProps>((props, ref) => {
  const { verticalPaneIds, paneStyles, collapseClass, horizontalPaneIndex } = props;
  let collapsed = collapseClass === 'collapsedLeft' || collapseClass === 'collapsedRight'
  const horizontalPaneRef = useRef(document.createElement('div'));

  const getPaneContent = (paneId: string, index: number): JSX.Element => {
    if (paneId in specialPaneTypes) {
      return <NoteList collectionType={specialPaneTypes[paneId]['collectionType']} />
    } else {
      return <ReaderPane horizontalPaneIndex={horizontalPaneIndex} verticalPaneIndex={index} documentId={paneId} />
    }
  }

  const getPaneHeader = (paneId: string): JSX.Element => {
    if (paneId in specialPaneTypes) {
      return <EditorHeader collapsed={collapsed} documentId={paneId} title={specialPaneTypes[paneId]['title']} />
    } else {
      return <EditorHeader collapsed={collapsed} documentId={paneId}/>
    }
  }

  const getVerticalPaneStyles = (index: number) => {
    let negativeIndex = verticalPaneIds.length - 1 - index;
    return {
      top: index * verticalOffset,
      bottom: negativeIndex * verticalOffset
    }
  }
  
  const focusState = useThing<FocusState>('focusState')
  useEffect(() => {
    if (focusState) {
      const { focus } = focusState.state
      let editorHeader = document.getElementById('pane-' + focus)
      let verticalIndex = verticalPaneIds.findIndex((id) => id === focus)
      
      if (editorHeader && horizontalPaneRef.current && verticalIndex !== -1) {
        editorHeader.style.position = 'static';
        let offsetTop = editorHeader.offsetTop;
        editorHeader.style.position = 'sticky';
        const { top, bottom } = getVerticalPaneStyles(verticalIndex)

        horizontalPaneRef.current.scrollTo({
          top: offsetTop - top - bottom,
          left: 0,
          behavior: 'smooth'
        })
      }
    }
  }, [focusState, verticalPaneIds])

  useEffect(() => {
    let sub: Subscription;
    if (!collapsed) {
      sub = docStore.thingSub('cursorPosition')
      .subscribe(() => {
        const position = getState('cursorPosition');
        if (!position) return;
        
        if (horizontalPaneIndex === position.horizontalPaneIndex) {
          // don't need to worry if we are on the bottom pane
          if (position.verticalPaneIndex === verticalPaneIds.length - 1) return;

          const stackedBeneathHeight = (verticalPaneIds.length - position.verticalPaneIndex) * verticalOffset

          if (window.innerHeight - stackedBeneathHeight - position.bottom < 0) {
              horizontalPaneRef.current.scrollBy(0, 150);
          }
          // get next pane header down
          // const nextPaneId = verticalPaneIds[position.verticalPaneIndex + 1]
          // const nextPaneEl = document.getElementById('pane-' + nextPaneId)
          
          // if (!nextPaneEl) return;
          // const { top } = nextPaneEl?.getBoundingClientRect();
          // const diff = position.bottom - top;
          
          // if (diff > 0) {

          //   horizontalPaneRef.current.scrollBy(0, 150);
          // }
        }
      })  
    }

    return () => sub?.unsubscribe();
  }, [collapsed, horizontalPaneIndex, verticalPaneIds.length])



  const getVerticalPanes = () => {
    const headers = verticalPaneIds.map((paneId, index) => {
      const headerStyles = getVerticalPaneStyles(index)
      const paneHeader = getPaneHeader(paneId)
      return (
        <div id={'pane-' + paneId} className={styles.paneHeader} style={headerStyles}>
          {paneHeader}
        </div>
      )
    });

    const panes = verticalPaneIds.map((paneId, index) => {
      const paneContent = getPaneContent(paneId, index)
      return (
        <div className={styles.paneContent}>
          {paneContent}
        </div>
      )
    });
    
    if (!collapsed) {
      return verticalPaneIds.map((paneId, i) => {
        return (
          <React.Fragment key={paneId}>
            {headers[i]}
            {panes[i]}
          </React.Fragment>
        )
      })
    } else {
      return (
        <>
          {headers.map((header, i) => {
            return (<React.Fragment key={verticalPaneIds[i] + 'header'}>
              {header}
            </React.Fragment>)
          })}
          {panes.map((pane, i) => {
            return (<React.Fragment key={verticalPaneIds[i] + 'pane'}>
            {pane}
          </React.Fragment>)
          })}
      </>
      )
    }
  }

  return (
    <div 
      ref = {horizontalPaneRef}
      className={[styles.horizontalPane, styles[collapseClass]].join(' ')}
      style={paneStyles}>
        {getVerticalPanes()}
    </div>
  )
}