import React, { useState } from 'react';
import styles from '../styles/NewNote.module.scss'
import { Input } from './widgets/Input';
import { toLower } from 'lodash';
import { docStore } from '../store';
import { collectionTypes } from '../util/links';
import { ColorPicker } from './ColorPicker';
import { makeNote } from '../models/NoteThing';
import { NoteTag } from '../models/NoteTag';


function randomColor() {
  return '#' + Math.floor(Math.random()*16777215).toString(16)
}

export interface NewNoteProps {
  collectionType: string
}

export function NewNote(props: NewNoteProps) {
  const { collectionType } = props;
  const [inputFocus, setInputFocus] = useState(false);
  const [color, setColor] = useState(randomColor());


  const newNote = (title: string) => {
    if (title.trim()) {
      let noteColor = collectionType === 'tag' ? color : undefined
      const note = makeNote(collectionType, {color: noteColor, title});
      docStore.update(note);
    }
    setInputFocus(false);
    setColor(randomColor());
  }

  const validate = collectionType === 'tag' ? 
    (input: string) => {
      let allTags: NoteTag[] = docStore.getAll('tag');
      if (allTags.some((tag) => tag.title === '#' + input)) {
        return false;
      } else {
        return true;
      }
    } : undefined;


  const tagTransform = collectionType === 'tag' ?
    (input: string) => {
      return toLower(input.replace(' ', ''));
    } : undefined;

  return (
    <div 
      className={[styles.newNote, inputFocus ? styles.inputFocus : ''].join(' ')}>
        <Input
          onFocus={() => setInputFocus(true)}
          onBlur={() => setInputFocus(false)}
          preventBlurSubmit={true}
          validation={validate}
          transform={tagTransform}
          className={[styles.newNoteInput, styles[collectionType]].join(' ')} 
          initialValue='' 
          placeholder={(collectionType === 'tag' ? '# ' : '') + 'Add ' + collectionTypes[collectionType]} 
          onSubmit={newNote}/>

          <div className={styles.colorPickerContainer}>
            {collectionType === 'tag' && <ColorPicker color={color} updateColor={setColor} />}
          </div>
    </div>
  )
}

