import React from 'react';
import styles from '../styles/Feedback.module.scss'

export function Feedback() {
  return (
    <div className={styles.feedbackContainer}>
      <div className={styles.feedback}>
        <h1 className={styles.feedbackHeader}>Feedback!</h1> 
        
        {/* @ts-ignore */}
          <form name="feedback" method="post" className={styles.feedbackForm}>
            {/* For netlify form */}
            <input type="hidden" name="form-name" value="feedback"></input>

            <textarea name="feedback"></textarea>
            <input type="email" name="email" placeholder={"Email"}>
            </input>

            <button type="submit"> Submit </button>
          </form>

        <h3>Thanks for helping!</h3>
      </div>
    </div>
  )
}

