import { docStore } from "../store";
import { defaultState } from "../models/State";
import { NoteThing } from "../models/NoteThing";

export const documentListKey = 'documentList'
export const tagListKey = 'tagList'

const documentListTitle = 'Documents'
const tagListTitle = 'Tags'

export const collectionTypes: Record<string, string> = {
  'tag': 'Tag',
  'doc': 'Document',
  'state': 'State'
}

interface SpecialPaneTypes {
  [noteTypeKey: string]: {
    collectionType: string
    title: string
  }
}


export const specialPaneTypes: SpecialPaneTypes = {
  [documentListKey]: {
   collectionType: 'doc',
   title: documentListTitle,
  },
  [tagListKey]: {
    collectionType: 'tag',
    title: tagListTitle,
  }
}

export function encodeLink(paneData: string[][]): string {
  return encodeURIComponent(JSON.stringify(paneData))
}

export function decodeLink(url: string | undefined): string[][] {
  if (!url) return [];
  return JSON.parse(decodeURIComponent(url)) || []
}

export function fixBrokenLink(currentState: string[][]): string {
  let newRoute = false;
  for (let [ i, horizontalPane ] of Object.entries(currentState)) {
    let horizontalPaneLength = horizontalPane.length;
    currentState[parseInt(i)] = horizontalPane.filter((paneId) => {
      if (paneId in specialPaneTypes) return true;
      if (docStore.get(paneId)) return true;
      return false;
    })

    if (currentState[parseInt(i)].length !== horizontalPaneLength) newRoute = true;
  }

  if (newRoute) {
    currentState = currentState.filter(horizontalPane => horizontalPane.length)
    return encodeLink(currentState);
  }
  else return ''
}

function currentState(location: any): string[][] {
  return decodeLink(location.pathname.slice(1))
}

export function newDocLink(documentId: string): string {
  const link = [[documentId]]
  return encodeLink(link);
}

export function generateNextPaneLink(documentId: string, location: any, paneIndex: number): string {
  let current = currentState(location)
  if (current.flat().some(docId => docId === documentId)) { return encodeLink(current) }
  let nextPane = current[paneIndex + 1]
  if (nextPane) nextPane.push(documentId)
  else current[paneIndex + 1] = [documentId]

  return encodeLink(current)
}

export function isDocOpen(documentId: string, location: any) {
  let current = currentState(location)
  if (current.flat().some(docId => docId === documentId)) return true;
  return false;
}



export function removeDocFromLink(documentId: string, location: any, paneIndex?: number): string {
  let current = currentState(location)
  if (typeof paneIndex === 'undefined') {
    paneIndex = current.findIndex((horizontalPane) => {
      if (horizontalPane.includes(documentId)) return true;
      return false;
    })
    if (paneIndex === -1) return encodeLink(current);
  }
  current[paneIndex] = current[paneIndex].filter(id => id !== documentId)
  if (!current[paneIndex].length) current.splice(paneIndex, 1);
  return encodeLink(current);
}

export function insertDocAtEnd(documentId: string, location: any) {
  const current = currentState(location);
  if (isDocOpen(documentId, location)) {
    updateFocusState(documentId);
    return '';
  }
  current.push([documentId]);
  return encodeLink(current);
} 

export function insertDocAtBeg(documentId: string, location: any) {
  const current = currentState(location);
  if (isDocOpen(documentId, location)) {
    updateFocusState(documentId);
    return '';
  }
  
  const documentPaneStart = current.findIndex((pane) => !(pane[0] in specialPaneTypes))

  let newState: string[][];

  if (documentPaneStart === -1) {
    current.push([documentId]);
    newState = current;
    console.log(newState);
  } else {
    const beginning = current.slice(0, documentPaneStart);
    beginning.push([documentId]);
    const end = current.slice(documentPaneStart);
    newState = beginning.concat(end);
  }

  return encodeLink(newState);  
}


export function toggleDocListLink(location: any): string {
  return toggleSpecialPaneType(location, documentListKey);
}

export function toggleTagListLink(location: any): string {
  return toggleSpecialPaneType(location, tagListKey);
}

export function toggleSpecialPaneType(location: any, specialPaneType: string) {
  let current = currentState(location);
  // find which horizontal pane is holding the documentList.
  // splice out that pane.
  let docPaneIndex = current.findIndex((horizontalPane) => {
    if (horizontalPane[0] === specialPaneType) return true;
    return false;
  })
  if (docPaneIndex !== -1) {
    current.splice(docPaneIndex, 1);
  }
  else {
    current.unshift([specialPaneType]);
  }
  return encodeLink(current);
}

function updateFocusState(focus: string) {
  docStore.update({
    ...defaultState('focusState'),
    state: {
      focus
    }
  })
}

export function moveFocusLeft(location: any, horizontalPaneIndex: number) : boolean {
  if (horizontalPaneIndex === 0) return false;
  let current = currentState(location);

  let focus = current[horizontalPaneIndex - 1][0]
  updateFocusState(focus)
  return true;
}

export function moveFocusRight(location: any, horizontalPaneIndex: number) : boolean {
  let current = currentState(location);
  if (horizontalPaneIndex === current.length - 1) return false;

  let focus = current[horizontalPaneIndex + 1][0]
  updateFocusState(focus)
  return true;
}

export function moveFocusUp(location: any, horizontalPaneIndex: number, verticalPaneIndex: number): boolean {
  if (verticalPaneIndex === 0) return false;
  let current = currentState(location);
  
  let focus = current[horizontalPaneIndex][verticalPaneIndex - 1]
  updateFocusState(focus)
  return true;
}

export function moveFocusDown(location: any, horizontalPaneIndex: number, verticalPaneIndex: number): boolean {
  let current = currentState(location);
  if (verticalPaneIndex === current[horizontalPaneIndex].length - 1) return false;
  
  let focus = current[horizontalPaneIndex][verticalPaneIndex + 1]
  updateFocusState(focus)
  return true;
}

export function openNetworkUrl(location: any, noteId: string, includeNote: Boolean = false) {
  const current = currentState(location);
  const note = docStore.get(noteId);
  if (!note) return encodeLink(current);

  const alreadyOpen = current.flat().reduce((prev, curr) => {
    return {
      ...prev,
      [curr]: true
    }
  }, {});

  const addNotes = BFSNotes(note, alreadyOpen, includeNote);
  const newUrlState = current.concat(addNotes);

  return encodeLink(newUrlState);
}

function BFSNotes(note: NoteThing, alreadyVisited: Record<string, true> = {}, includeRoot: Boolean = false) {
  if (!note) return [];
  const visited = {
    ...alreadyVisited,
  }
  const found = [];
  const queue = [note];

  let level = 0;

  while (queue.length) {
    found.push([] as string[]);
    let levelSize = queue.length;

    while (levelSize--) {
      const current = queue.shift()!;
      found[level].push(current._id);
      for (let neighborId of current?.links.map(link => link.targetId)) {
        if (!visited[neighborId]) {
          const neighborDoc = docStore.get(neighborId);
          queue.push(neighborDoc);
          visited[neighborId] = true;
        }
      }
    }

    level++;
  }


  if (!includeRoot) {  // remove the starting node from the result
    found.shift();
  }

  return found;

}