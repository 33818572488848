import { v4 as uuid } from 'uuid';
import { appURL } from '../project';
import { docStore } from '../store';
import { FilterState } from './State';
import { NoteThing, StoredLink } from './NoteThing';
import { NoteTag } from './NoteTag';


export interface Doc extends NoteThing {
  collectionType: 'doc'
}

export function makeDocument(makeDoc?: Partial<NoteThing>): Doc {
  const title = makeDoc?.title || 'Untitled Document'
  // const _id = slug(title);
  return {
    _id: uuid(),
    _deleted: false,
    links: [],
    backlinks: [],
    tags: [],
    content: "",
    public: false,
    ...makeDoc,
    title,
    collectionType: 'doc',
  }
}



export function getDocumentLink(document: Doc) {
  return `[${document._id}](${appURL}/${document._id})`
}

export function saveDocument(docId: string, inst: CodeMirror.Editor) {
  const doc: NoteThing = docStore.get(docId);
  if (!doc) return;

  let markers = inst.getAllMarks()

  // map markers to stored links  
  let newLinks = markers.map(markerToStoredLink).filter(filterBrokenLinks)

  // repair backlinks of links that don't exist anymore
  let obsoleteLinks = findObsoleteLinks(doc, newLinks)
  fixObsoleteLinks(obsoleteLinks, doc._id);

  doc.links = newLinks;

  // add new backlinks to linked documents
  doc.links.forEach((newLink) => addNewBacklink(newLink, doc._id))

  doc.content = inst.getValue();
  docStore.update(doc)
}


function addNewBacklink(newLink: StoredLink, sourceDocumentId: string) {
  let doc: Doc = docStore.get(newLink.targetId);
  if (!doc.backlinks.includes(sourceDocumentId)) {
    doc.backlinks.push(sourceDocumentId);
    docStore.update(doc);
  }
}

function fixObsoleteLinks(obsoleteLinks: StoredLink[], sourceDocumentId: string) {
  obsoleteLinks.forEach((obsoleteLink) => {
    let doc = docStore.get(obsoleteLink.targetId);
    if (!doc) return;
    removeBacklinks(doc, sourceDocumentId);
    docStore.update(doc);
  })
}

function findObsoleteLinks(doc: NoteThing, newLinks: StoredLink[]): StoredLink[] {
  let obsoleteLinks: StoredLink[] = [];
  for (let oldLink of doc.links) {
    if (!newLinks.find((newLink) => newLink.targetId === oldLink.targetId)) {
      obsoleteLinks.push(oldLink)
    }
  }

  return obsoleteLinks;
}

function removeBacklinks(doc: Doc, backlinkedDoc: string) {
  let backlinkIndex = doc.backlinks.findIndex(backlink => backlink === backlinkedDoc);
  if (backlinkIndex !== -1) {
    doc.backlinks.splice(backlinkIndex, 1)
  }
}

function removeLinks(doc: Doc, targetDocumentId: string) {
  doc.links = doc.links.filter((link) => link.targetId !== targetDocumentId)
}

export function removeNote(noteId: string) {
  const note = docStore.get(noteId);
  if (!note) return;

  let deletedThing = removeNoteThing(note);

  if (note.collectionType === 'tag') {
    deleteTag(note);
  }

  docStore.update(deletedThing);
}

function deleteTag(tag: NoteTag) {
  // find all documents that are tagged with this tag and remove this tag from those documents
  tag.taggedDocuments.forEach((docId) => {
    const doc: Doc = docStore.get(docId);

    const tagIndex = doc.tags.findIndex((tagId) => tagId === tag._id);
    if (tagIndex !== -1) doc.tags.splice(tagIndex, 1);

    docStore.update(doc);
  });

  // also update filter state
  const tagFilter: FilterState = docStore.get('tagFilter');
  const tagIndex = tagFilter.state.filterTags.findIndex((tagId) => tagId === tag._id);

  if (tagIndex !== -1) {
    tagFilter.state.filterTags.splice(tagIndex, 1);
    docStore.update(tagFilter);
  }

  tag._deleted = true;

  return tag;
}

function removeNoteThing(note: NoteThing) {
    // remove all backlinks back-referencing this document (everything this document points to)
    fixObsoleteLinks(note.links, note._id)

    // remove all links referencing this document (everything this document is backlinked to)
    note.backlinks.forEach((backlink) => {
      let backlinkedDoc = docStore.get(backlink);
      if (!backlinkedDoc) return;
      removeLinks(backlinkedDoc, note._id);
      docStore.update(backlinkedDoc);
    }) 

    // remove this document from all tag (that it is tagged with

    note.tags && note.tags.forEach((tagId) => {
      let tag = docStore.get(tagId);
      if (!tag) return;
      deleteTagRefToDoc(tag, note as Doc);

      docStore.update(tag);

    })
    note._deleted = true;
    return note;
}



function markerToStoredLink(marker: CodeMirror.TextMarker): StoredLink {
    let { from, to } = marker.find();
    let targetId = marker.replacedWith?.getAttribute('linkForId') || ''

    return {
      from,
      to,
      targetId
    }
}

function filterBrokenLinks(storedLink: StoredLink): boolean {
  if (!storedLink.targetId) return false;
  if (!docStore.get(storedLink.targetId)) return false;
  return true;
}

export function makeNewDoc(makeDoc: Partial<Doc>): Doc {
  const newDoc = makeDocument(makeDoc);
  const currentFilter: FilterState = docStore.get('tagFilter');

  currentFilter.state.filterTags.forEach((tagId) => {
    addTagToDoc(tagId, newDoc);
  });

  docStore.update(newDoc);

  return newDoc;

}


export function addTagToDoc(tagId: string, doc: Doc) {
  if (!doc) return;
  let tag = docStore.get(tagId);
  if (!tag) return;

  doc.tags.push(tagId)
  tag.taggedDocuments.push(doc._id)

  docStore.update(tag);
  docStore.update(doc);
}

export function removeTagFromDoc(tagId: string, doc: Doc) {
  const tag: NoteTag = docStore.get(tagId);
  if (!tagId || !tag || !doc) return;
  
  // remove document from tag
  deleteTagRefToDoc(tag, doc);

  // remove tag from document
  const tagIndex = doc.tags.findIndex((tagId) => tagId === tag._id)
  if (tagIndex !== -1) doc.tags.splice(tagIndex, 1);
  
  // update both
  docStore.update(doc);
  docStore.update(tag);
}


function deleteTagRefToDoc(tag: NoteTag, doc: Doc) {
  const docIndex = tag.taggedDocuments.findIndex((docId) => docId === doc._id);
  if (docIndex !== -1) tag.taggedDocuments.splice(docIndex, 1);
}

