import { useState, useEffect } from 'react';
import { filter } from 'rxjs/operators';
import { docStore } from '../store';

export function useThing<T>(documentId: string): T {
  // if (documentId == undefined) return undefined;
  const [document, setDocument] = useState(docStore.get(documentId))

  useEffect(() => {
    setDocument(docStore.get(documentId))
    const sub = docStore.allUpdates.pipe(
      filter((newDocumentId) => newDocumentId === documentId)
    ).subscribe((documentId) => {
      setDocument(docStore.get(documentId))
    })

    return () => sub.unsubscribe()
  }, [documentId]);

  return document;
}