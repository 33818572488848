import React, { useEffect, useRef, useState } from 'react';
import styles from '../styles/ReaderView.module.scss'
import { fromEvent, Subscription } from 'rxjs';
import { auditTime } from 'rxjs/operators';
import { HorizontalPane } from './HorizontalPane';
import { useThing } from '../hooks/useThing';
import { FocusState } from '../models/State';

/* eslint react-hooks/exhaustive-deps: "off" */

export interface ReaderViewProps {
  horizontalPanes: string[][]
}

const horizontalOffset = 40;

export function ReaderView(props: ReaderViewProps) {
  const { horizontalPanes } = props;

  const ref = useRef(document.createElement("div"));
  const [scrollPosition, setScrollPosition] = useState(0);

  const focusState = useThing<FocusState>('focusState')

  let coef = 0.31;
  if (window.matchMedia("(max-width: 700px)").matches) {
    coef = 1;
  }
  const {width: screenWidth} = document.body.getBoundingClientRect()

  const paneWidth = screenWidth * coef;
  let totalWidth = paneWidth * horizontalPanes.length;
  let totalScrollWith = Math.ceil(totalWidth - screenWidth);



  useEffect(() => {
    if (focusState) {
      const { focus } = focusState.state
      const horizontalPaneIndex = horizontalPanes.findIndex((verticalPane) => {
        if (verticalPane.find(id => id === focus)) return true;
        else return false;
      })

      if (ref.current && horizontalPaneIndex !== -1) {
        let collapseClass = getHorizontalCollapseClass(scrollPosition, horizontalPaneIndex)

        if (collapseClass) {
          ref.current.scrollTo({
            top: 0,
            left: horizontalPaneIndex * paneWidth,
            behavior: 'smooth'
          })
        }
      }
    }
  }, [focusState])

  useEffect(() => {
    let sub: Subscription | undefined;
    if (ref.current !== null) {
      setScrollPosition(ref.current.scrollLeft)
      sub = fromEvent(ref.current, 'scroll').pipe(
        auditTime(250)
        ).subscribe((e: any) => {
          setScrollPosition(ref.current.scrollLeft);
        })
    }
    return () => { if (sub) sub.unsubscribe() }
  }, [])

  const getHorizontalCollapseClass = (scrollPosition: number, index: number) => {
    if (scrollPosition > ((paneWidth * (index + 1) - ((index * horizontalOffset) + 2 * horizontalOffset)))) {
      return 'collapsedLeft'
    } else if (scrollPosition > (paneWidth * index) - (horizontalOffset * index)) {
      return 'collapsingLeft'
    } else {
      let distanceFromRight = totalScrollWith - scrollPosition;
      let negativeIndex = horizontalPanes.length - 1 - index;
      if (negativeIndex * paneWidth - (2 * horizontalOffset) < distanceFromRight + (horizontalOffset * negativeIndex) - paneWidth) return 'collapsedRight'
      if (negativeIndex * paneWidth < distanceFromRight) return 'collapsingRight'
    }
    return ''
  }

  const getHorizontalPaneStyles = (index: number): [any, string] => {
    let left = index * horizontalOffset
    let right = -(paneWidth - ((horizontalPanes.length - index) * horizontalOffset))
    let collapseClass = getHorizontalCollapseClass(scrollPosition, index)
    let paneStyle = {width: paneWidth + 'px', left: left, right: right}

    return [paneStyle, collapseClass]
  }

  return (
    <div 
      ref={ref}
      className={styles.readerContainer}>
      <div
        style = {{width: totalWidth + 'px'}}
        className={styles.readerView}>
        {horizontalPanes.map((verticalPaneIds, i) => {
          const [paneStyle, collapseClass] = getHorizontalPaneStyles(i);
          return (
            <HorizontalPane
              horizontalPaneIndex={i}
              key={i}
              verticalPaneIds={verticalPaneIds}
              paneStyles={paneStyle}
              collapseClass={collapseClass}
            />
          )
        })}
      </div>
    </div>
  )
}

