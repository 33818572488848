import React from 'react';
import styles from '../styles/Tags.module.scss'
import { NoteTag, noteToOption, filterDocTags } from '../models/NoteTag';
import { Tag } from './Tag';
import { Select } from './Select';
import { useCollection } from '../hooks/useCollection';


export interface TagsProps {
  addTag: (tagId: string) => void
  removeTag: (tagId: string) => void
  hasTagIds: string[]
}

export function Tags(props: TagsProps) {
  const { hasTagIds, addTag, removeTag } = props;

  const tags = useCollection<NoteTag>('tag', false);

  let tagOptions = filterDocTags(tags, hasTagIds).map(noteToOption);

  return (
    <div className={styles.tags}>
      {hasTagIds.map((tag) => {
        return (
          <Tag key={tag} deleteTag={() => removeTag(tag)} tagId={tag}/>
        )})}
      {hasTagIds.length < tags.length && 
      <Select
        onSubmit={addTag} 
        options={tagOptions}
        />}
    </div>
  )
}

