import React, { useState } from 'react';
import styles from '../styles/ColorPicker.module.scss'
import { CompactPicker } from 'react-color';


export interface ColorPickerProps {
  color: string
  updateColor: (color: string) => void
}

export function ColorPicker(props: ColorPickerProps) {
  const { color, updateColor } = props;

  const [showPicker, setShowPicker] = useState(false);

  

  return (
    <div         
      onMouseLeave={() => setShowPicker(false)}
      onMouseEnter={() => setShowPicker(true)}
      className={styles.colorPicker}>
      <div 
        style={{backgroundColor: color}} className={styles.colorCircle}>
        {showPicker && <div className={styles.picker}>
          <CompactPicker
            color={color}
            onChange={(c) => updateColor(c.hex)}
            />
        </div>}
      </div>
    </div>
  )
}

