import React, { useState } from 'react';
import styles from '../styles/Backlink.module.scss'
import { useThing } from '../hooks/useThing';
import { Doc } from '../models/Doc';
import { PopoverWrapper } from './PopoverWrapper';


export interface BacklinkProps {
  backlink: string
  onClick: (e: any, backlink: string) => void
}

export function Backlink(props: BacklinkProps) {
  const { backlink, onClick } = props;

  const backlinkDoc = useThing<Doc>(backlink);

  const [popover, showPopover] = useState(false);
  

  return (
    <PopoverWrapper
      isOpen={popover}
      documentId={backlink}>
      <div>
        {backlinkDoc && <div onMouseEnter={() => showPopover(true)} onMouseLeave={() => showPopover(false)} 
          onClick={(e) => onClick(e, backlink)}className={styles.backlink} key={backlink}>
          <h5 className={styles.backlinkHeader}>
            {backlinkDoc.title}
          </h5>
          <p className={styles.backlinkContent}>
            {backlinkDoc.content}
          </p>
        </div>}
      </div>
    </PopoverWrapper>
  )
}

