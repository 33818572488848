import { makeDocument } from "./Doc"

import { makeTag } from "./NoteTag"

export interface NoteThing {
  _id: string
  _deleted: boolean
  collectionType: string
  title: string
  links: StoredLink[]
  backlinks: string[]
  content: string
  public: boolean
  tags: string[]
  color?: string
}

export interface StoredLink {
  targetId: string,
  from: CodeMirror.Position
  to: CodeMirror.Position
}

export function makeNote(collectionType: string, makeNoteThing?: Partial<NoteThing>) {
  if (collectionType === 'doc') return makeDocument(makeNoteThing)
  else if (collectionType === 'tag') return makeTag(makeNoteThing)
}

