import { docStore } from "../store"

/* eslint @typescript-eslint/consistent-type-assertions: "off" */

export interface State {
  _id: string
  state: any
  collectionType: 'state'
  persist?: boolean
}

export interface ModalState extends State {
  _id: 'modalState'
  state: {
    showModal: boolean,
    filterText: string,
    coords: any
  }
}

export interface FocusState extends State {
  _id: 'focusState'
  state: {
    focus: string
  }
}

export interface CursorPosition extends State {
  _id: 'cursorPosition'
  state: {
    verticalPaneIndex: number
    horizontalPaneIndex: number
    bottom: number
  }
}

export interface FilterState extends State {
  _id: 'tagFilter'
  persist: true,
  state: {
    filterTags: string[]
  }
}

export function defaultState(stateId: string): State {
  return defaultStates[stateId];
}

export const defaultStates: Record<string, State> = {
  modalState: <ModalState> {
    _id: 'modalState',
    collectionType: 'state',
    state: {
      showModal: false,
      filterText: '',
      coords: {}
    }
  },
  focusState: <FocusState> {
    _id: 'focusState',
    collectionType: 'state',
    state: {
      focus: ''
    }
  },
  cursorPosition: <CursorPosition> {
    _id: 'cursorPosition',
    collectionType: 'state',
    state: {
      verticalPaneIndex: 0,
      horizontalPaneIndex: 0,
      bottom: 0,
    }
  },
  tagFilter: <FilterState> {
    _id: 'tagFilter',
    collectionType: 'state',
    persist: true,
    state: {
      filterTags: []
    }
  }
}

export function updateState(stateId: string, newState: any) {
  let oldState = docStore.get(stateId) || defaultState(stateId);

  oldState.state = newState;

  docStore.update(oldState);
}

export function getState(stateId: string) {
  let state = docStore.get(stateId) || defaultState(stateId);

  return state.state;
}