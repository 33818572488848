import React from 'react';
import { RiDeleteBack2Line } from 'react-icons/ri';
import styles from '../styles/Tag.module.scss'
import { useThing } from '../hooks/useThing';
import { NoteTag, getTagColorStyles } from '../models/NoteTag';


export interface TagProps {
  tagId: string
  deleteTag: () => void
}

export function Tag(props: TagProps) {
  const { tagId, deleteTag } = props;
  const tag = useThing<NoteTag>(tagId)

  const colorStyles = getTagColorStyles(tag);

  return (
    tag && <span className={styles.tag} onClick={deleteTag} style={colorStyles}>
      {tag.title} <RiDeleteBack2Line className={styles.deleteIcon} />
    </span>
  )
}

